import React from 'react';

const Element2 = (props) => {
    return(
    <div className="wrap2">
        <h1>We've got what you need!</h1>
        <div className="blockWhite"></div>
        <p>Start Bootstrap has everything you need to get your new website up and running in no time! All of the templates and themes on Start Bootstrap are open source, free to download, and easy to use. No strings attached!</p>
        <button>GET STARTED!</button>
    </div>
    );
}

export default Element2;