import React from 'react';

const Element1 = (props) => {
return(
<div className="wrap1">
    <h1>YOUR FAVORITE SOURCE OF FREE BOOTSTRAP THEMES</h1>
    <div className="block"></div>
    <p>Start Bootstrap can help you build better websites using the Bootstrap CSS framework! Just download your template and start going, no strings attached!</p>
    <button>FIND OUT MORE</button>
</div>
);
    

}

export default Element1;