import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import './App.css';
import Navigation from './components/navigation';
import Element1 from './components/element1';
import Element2 from './components/element2';
import Element3 from './components/element3';
import Element4 from './components/element4';
import Element5 from './components/element5';
import Element6 from './components/element6';

class App extends Component {
  render() {
    return (
      <div className="App">
      <Navigation/>
      <Container fluid>
      <Row>
        <Col className="element1">
          <Element1/>
        </Col>
      </Row>
      <Row>
          <Col className="element2">
            <Element2/>
          </Col>
        </Row>
        <Row>
          <Col className="element3">
            <Element3/>
          </Col>
        </Row>
        <Row>
          <Col className="element4">
            <Element4/>
          </Col>
        </Row>
        <Row>
          <Col className="element5">
            <Element5/>
          </Col>
        </Row>
        <Row>
          <Col className="element6">
            <Element6/>
          </Col>
        </Row>
      </Container>
      </div>
    );
  }
}

export default App;
