import React from 'react';
import{
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';

export default class Navigation extends React.Component{
    //constructor
    constructor(props) {
        super(props);
        //state
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        }
    }
    //function
    toggle(){
        this.setState({
            isOpen:!this.state.isOpen
        });
    }
    //render
    render(){
        return(
            <div>
            <Navbar color="light" light expand="md">
                <NavbarBrand href="/">Creative</NavbarBrand>
                <NavbarToggler onClick={this.toggle}/>
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <NavLink href="#service">Service</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#gallery">Gallery</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#contact">Contact</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
            </div>
        );
    }

}
