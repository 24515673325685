import React from 'react';

const Element5 = (props) => {
    return(
    <div className="wrap5">
        <h1>Free Download at Start Bootstrap!</h1>
        <button>GET STARTED!</button>
    </div>
    );
}

export default Element5;