import React from 'react';
import {  Row, Col } from 'reactstrap';

const Element6 = (props) => {
    return(
        <div className="wrap6" id="contact">
            <h1>Let's Get In Touch!</h1>
            <div className="block"></div>
            <p>Ready to start your next project with us? That's great! Give us a call or send us an email and we will get back to you as soon as possible!</p>
            <div className="wrap6a">
            <Row>
                <Col md="6">
                <div className="phone"></div>
                    <p>123-456-6789</p>
                </Col>
                <Col md="6">
                <div className="mail"></div>
                    <p>feedback@startbootstrap.com</p>
                </Col>
            </Row>
            
            </div>
        </div>
    );
}

export default Element6;